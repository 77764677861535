import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router/router.js'; // 导入路由文件
import createPersistedState from 'vuex-persistedstate';
import {Message} from 'element-ui'

Vue.use(Vuex);

const state = {
    draftsObj: {},
    token: null,
    tokenExpirationTime: null,
    loginNumber: 0,
    isLoggedIn: false
};

const getters = {
    getDraftsObj: (state) => state.draftsObj,
    getToken: (state) => state.token,
    getExpirationTime: (state) => state.tokenExpirationTime,
    getLoggedIn: (state) => state.isLoggedIn,
    getLoginNumber: (state) => state.loginNumber,
    clearToken: (state) => (state.token = null),
};
const setters = {
    clearToken: (state) => (state.token = null),
};
const mutations = {
    setDraftsObj(state, obj) {
        state.draftsObj = {...obj};
    },
    setToken(state, {token, expirationTime}) {
        // 设置 token
        state.token = token;
        // 设置过期时间
        state.tokenExpirationTime = expirationTime;
    },
    setLoginNumber() {
        state.loginNumber++;
    },
    clearToken(state) {
        state.token = null;
        localStorage.removeItem('token');
        Message({
            type: "warning",
            message: "登陆过期，请重新登陆!",
        });
        router.push('/login');
        router.go(0);
    },
    clearTokenOut(state) {
        state.token = null;
        localStorage.removeItem('token');
    },
    loggedIn(state) {
        state.isLoggedIn = true;
    },
    removeLoggedIn(state) {
        state.isLoggedIn = false;
    }
};

const actions = {
    // 在登录成功后调用此 action 来设置 Token 和过期时间
    setLoggedIn({commit}, {token, expirationTime}) {
        commit('setToken', {token, expirationTime});
    },
};

const checkExpiration = (store) => {
    let token = store.getters.getToken;
    if (token != null) {
        let expirationTime = store.getters.getExpirationTime;
        // 定时器 判断到达过期时间
        setInterval(() => {
            if (Date.now() > new Date(expirationTime)) {
                // store.commit('clearToken');
            }
        }, 1000 * 60); // 每分钟检查一次
    }
};

export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions,

    plugins: [createPersistedState(), checkExpiration],
});
